<template>
    <div class='container'>
        <HeaderNav />
        <div class="content">
            <div class="inner">
                <div class="tit">联系我们</div>
                <div class="cards">
                    <div class="card">
                        <img src="@/assets/img/contact_phone.png" alt="">
                        <div class="iconTit">电话</div>
                        <div class="iconInfo">{{contactData.phone}}</div>
                    </div>
                    <div class="card">
                        <img src="@/assets/img/contact_email.png" alt="">
                        <div class="iconTit">邮箱</div>
                        <div class="iconInfo">{{contactData.email}}</div>
                    </div>
                    <div class="card">
                        <img src="@/assets/img/contact_fax.png" alt="">
                        <div class="iconTit">传真</div>
                        <div class="iconInfo">{{contactData.fax}}</div>
                    </div>
                    <div class="card">
                        <img src="@/assets/img/contact_address.png" alt="">
                        <div class="iconTit">地址</div>
                        <div class="iconInfo">{{contactData.address}}</div>
                    </div>
                </div>
            </div>
        </div>
        <FooterNav />
    </div>
</template>

<script>
    import HeaderNav from "@/components/HeaderNav"
    import FooterNav from "@/components/FooterNav"
    import {
        contactUsList
    } from "@/request/contact"
    export default {
        name: 'Contact',

        props: {

        },
        data() {
            return {
                contactData: {}
            };
        },
        computed: {

        },
        created() {

        },
        mounted() {
            this.getContacts()
        },
        watch: {

        },
        methods: {
            getContacts() {
                contactUsList({}).then(res => {
                    this.contactData = res.data[0]
                })
            }
        },
        components: {
            HeaderNav,
            FooterNav
        },
    };
</script>

<style scoped lang="scss">
    .content {
        width: 100%;
        height: 799px;
        background-image: url("../assets/img/contactBg.png");
        background-repeat: no-repeat;
        background-size: 100%;

        .inner {
            width: 1200px;
            padding-top: 133px;
            margin: 0 auto;

            .tit {
                font-size: 34px;
                font-weight: 600;
                text-align: left;
                color: #ffffff;
                line-height: 48px;
                margin-bottom: 39px;
            }

            .cards {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .card {
                    width: 285px;
                    height: 224px;
                    background: #ffffff;
                    border-radius: 20px;
                    box-shadow: 6px 10px 17px 0px rgba(1, 69, 133, 0.21);
                    margin-right: 23px;
                    box-sizing: border-box;
                    padding: 46px 0 0 0;

                    img {
                        display: block;
                        width: 54px;
                        height: 54px;
                        margin: 0 auto;
                        margin-bottom: 10px;
                    }

                    .iconTit {
                        font-size: 16px;
                        font-weight: 600;
                        text-align: center;
                        color: #266ddc;
                        line-height: 22px;

                        margin-bottom: 18px;
                    }

                    .iconInfo {
                        text-align: center;
                        height: 44px;
                        margin: 0 auto;
                        font-size: 16px;
                        font-family: PingFangSC, PingFangSC-Regular;
                        font-weight: 400;
                        color: #000000;
                        line-height: 22px;
                        word-break: break-all;
                        word-wrap: break-word;
                        white-space: wrap;
                    }

                }

                .card:last-child {
                    margin-right: 0;
                }
            }
        }
    }
</style>